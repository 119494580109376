import { AxiosHttpClient, HttpClient } from "@/shared/api";
import { TweetList } from "./domain";

const API_URL = import.meta.env.VITE_API_URL;

export class DashRepository {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new AxiosHttpClient(API_URL!);
  }

  async getList(past?: boolean): Promise<TweetList> {
    try {
      const result = await this.httpClient.get<TweetList>("/v1/tweets", {
        past,
      });
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
