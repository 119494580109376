import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ValhallaRepository } from "./repo";
import { ValhallaTweetList } from "./domain";
import { AxiosError } from "axios";

interface ValhalllaStateActions {
  fetchValhallaTweetList: () => Promise<ValhallaTweetList>;
  clearErrors: (action?: ACTIONS) => void;
  clearLoading: (action?: ACTIONS) => void;
}

export enum ACTIONS {
  FETCHING_VALHALLA_TWEETS = "FETCHING_VALHALLA_TWEETS",
}
const initialActionState = <T>(initialValue: T) =>
  Object.values(ACTIONS).reduce(
    (acc, action) => {
      acc[action] = initialValue;
      return acc;
    },
    {} as Record<ACTIONS, T>,
  );

interface ValhallaState {
  valhallaTweets: ValhallaTweetList | null;
  loading: Record<ACTIONS, boolean>;
  error: Record<ACTIONS, string | null | undefined>;
  actions: ValhalllaStateActions;
}

export const useValhallaStore = create<ValhallaState>()(
  immer((set) => ({
    valhallaTweets: null,
    loading: initialActionState(false),
    error: initialActionState(null),
    actions: {
      fetchValhallaTweetList: async () => {
        const ACTION = ACTIONS.FETCHING_VALHALLA_TWEETS;
        try {
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          const repo = new ValhallaRepository();
          const result = await repo.getValhallaList();
          set((state) => {
            state.valhallaTweets = result;
            state.loading[ACTION] = false;
            state.error[ACTION] = null;
          });
          return result;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] =
              (err as AxiosError)?.message || "Error getting valhalla tweets";
          });
          throw err;
        }
      },
      clearErrors: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            delete state.error[action];
          } else {
            Object.values(ACTIONS).forEach((action) => {
              delete state.error[action];
            });
          }
        });
      },
      clearLoading: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            state.loading[action] = false;
          } else {
            Object.values(ACTIONS).forEach((action) => {
              state.loading[action] = false;
            });
          }
        });
      },
    },
  })),
);
export const useValhalla = () =>
  useValhallaStore((state) => state.valhallaTweets);

export const useValhallaActions = () =>
  useValhallaStore((state) => state.actions);
