import { useEffect } from "react";
import { toast } from "@/components/toast";

type ErrorsState = Record<string, StoreError | null | undefined>;
type ErrorsCallback<T> = (action: T) => void;

const useToastErrors = <T,>(
  errors: ErrorsState,
  callback: ErrorsCallback<T>,
  actions?: T[],
) => {
  useEffect(() => {
    // Display relevant errors
    Object.entries(errors).forEach(([action, error]) => {
      if (error && (!actions || actions.includes(action as T))) {
        toast.error(error.title, error.message, { id: error.id });
        callback(action as T);
      }
    });
  }, [errors, actions, callback]);
};

export { useToastErrors };
