import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { DashRepository } from "./repo";
import { TweetList } from "./domain";
import { AxiosError } from "axios";

interface DashStateActions {
  fetchTweetList: () => Promise<TweetList>;
  clearErrors: (action?: ACTIONS) => void;
  clearLoading: (action?: ACTIONS) => void;
}

export enum ACTIONS {
  FETCHING_TWEETS = "FETCHING_TWEETS",
}

const initialActionState = <T>(initialValue: T) =>
  Object.values(ACTIONS).reduce(
    (acc, action) => {
      acc[action] = initialValue;
      return acc;
    },
    {} as Record<ACTIONS, T>,
  );

interface DashState {
  tweets: TweetList | null;
  pastTweets: TweetList | null;
  loading: Record<ACTIONS, boolean>;
  error: Record<ACTIONS, string | null | undefined>;
  actions: DashStateActions;
}

export const useDashStore = create<DashState>()(
  immer((set) => ({
    tweets: null,
    pastTweets: null,
    loading: initialActionState(false),
    error: initialActionState(null),
    actions: {
      fetchTweetList: async () => {
        const ACTION = ACTIONS.FETCHING_TWEETS;
        try {
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          const repo = new DashRepository();
          const resultProm = repo.getList();
          const pastResultProm = repo.getList(true);
          const [result, pastResult] = await Promise.all([
            resultProm,
            pastResultProm,
          ]);
          set((state) => {
            state.tweets = result;
            state.pastTweets = pastResult;
            state.loading[ACTION] = false;
            state.error[ACTION] = null;
          });
          return result;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] =
              (err as AxiosError)?.message || "Error getting tweets";
          });
          throw err;
        }
      },
      clearErrors: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            delete state.error[action];
          } else {
            Object.values(ACTIONS).forEach((action) => {
              delete state.error[action];
            });
          }
        });
      },
      clearLoading: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            state.loading[action] = false;
          } else {
            Object.values(ACTIONS).forEach((action) => {
              state.loading[action] = false;
            });
          }
        });
      },
    },
  })),
);
export const useDash = () => useDashStore((state) => state.tweets);

export const useDashActions = () => useDashStore((state) => state.actions);
