import { AxiosHttpClient, HttpClient } from "@/shared/api";
import { ValhallaTweetList } from "./domain";

const API_URL = import.meta.env.VITE_API_URL;

export class ValhallaRepository {
  httpClient: HttpClient;

  constructor() {
    this.httpClient = new AxiosHttpClient(API_URL!);
  }

  async getValhallaList(): Promise<ValhallaTweetList> {
    try {
      const result = await this.httpClient.get<ValhallaTweetList>(
        "/v1/tweets/valhalla",
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
