"use client";
import React from "react";
import { useEffect, useMemo } from "react";
import cx from "classnames";

import { useWalletActions, useWalletStore } from "@/state/wallet/store";
import { WalletBackend } from "@/state/wallet/domain";

import { Button } from "@/components/button";
import { Dialog, DialogContent, DialogHeader } from "@/components/dialog";
import { Text } from "./text";
import { Loading } from "./Loading";

type WalletSelectorProps = {
  open?: boolean;
  loading?: boolean;
  onOpenChange: (open: boolean) => void;
  onWalletSelect: (wallet: WalletBackend) => void;
};

const WalletSelector = ({
  open,
  loading,
  onOpenChange,
  onWalletSelect,
}: WalletSelectorProps) => {
  const { getWallets } = useWalletActions();
  const { loading: walletLoading, wallets } = useWalletStore();

  const anyLoading = useMemo(() => {
    return loading || Object.values(walletLoading).some((l) => l);
  }, [walletLoading, loading]);

  // Effects
  useEffect(() => {
    // Fetch the installed wallets
    getWallets();
  }, [getWallets]);

  // Show toasts for wallet errors
  // useToastErrors(error, clearError, [ACTIONS.GET_WALLETS]);

  // Handlers
  const handleOutsideInteract = (event: unknown) => {
    const e = event as React.MouseEvent;
    if ((e.target as HTMLElement)?.closest("wcm-modal")) {
      e.preventDefault();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onInteractOutside={handleOutsideInteract}
        className={"modal modal-dialog-content max-w-[400px]"}
      >
        {!anyLoading && (
          <>
            <DialogHeader className="modal modal-dialog-header items-center">
              <Text variant={"secondary"} size={"xl"}>
                Connect Wallet
              </Text>
            </DialogHeader>
            <div className={cx("flex flex-col gap-[16px] relative")}>
              {wallets.map(({ name, wallet }) => (
                <Button
                  key={name}
                  className="!w-full flex flex-1 items-center justify-start gap-4 font-bold p-4"
                  onClick={wallet ? () => onWalletSelect(wallet) : undefined}
                  disabled={anyLoading || !wallet}
                >
                  <p className="subtitle uppercase">{name}</p>
                </Button>
              ))}
            </div>
          </>
        )}

        {anyLoading && (
          <>
            <Loading />
            <Text
              variant={"primary"}
              size={"xl"}
              className="w-full text-center mt-[-30px]"
            >
              Connecting
            </Text>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { WalletSelector };
