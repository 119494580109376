import { Tweet } from "@/components/Tweet";
import { Text } from "@/components/text";
import { useValhallaActions, useValhallaStore } from "@/state/valhalla/store";
import React, { useEffect } from "react";

const Valhalla: React.FC = () => {
  const { valhallaTweets } = useValhallaStore();
  const { fetchValhallaTweetList } = useValhallaActions();

  useEffect(() => {
    if (!valhallaTweets) {
      fetchValhallaTweetList();
    }
  }, [valhallaTweets, fetchValhallaTweetList]);

  return (
    <>
      <img
        src="/images/valhalla.svg"
        className="md:max-w-[100vw] md:w-screen 2xl:ml-[calc((100vw_-_1500px)/-2)]"
      ></img>
      <div className="mt-[-15vw] grid grid-col-1 p-4 gap-5 ">
        {valhallaTweets?.items && valhallaTweets.items.length > 0 ? (
          valhallaTweets.items.map((valhallaTweet, i) => {
            return (
              <div
                className="p-8 border-solid border-2 border-white bg-black flex flex-col items-center"
                key={i}
              >
                <div className="grid grid-cols-[1fr,10px,1fr]  md:grid-cols-[1fr,20px,1fr] gap-[50px] items-center">
                  <div className="justify-self-end flex flex-col items-center">
                    <Text
                      variant="secondary"
                      className="text-[30px] md:text-[40px] mb-[20px]"
                    >
                      OG TWEET
                    </Text>
                    <Tweet
                      className="md:w-[300px]"
                      textClassname="md:text-[20px]"
                      text={valhallaTweet.og_tweet.content}
                      handle={valhallaTweet.og_tweet.author_username}
                      profile_pic={valhallaTweet.og_tweet.profile_pic}
                      link={valhallaTweet.og_tweet.link}
                    ></Tweet>
                  </div>
                  <img
                    src="/images/dividerVertical.svg"
                    className="h-[200px] w-[20px]"
                    alt="Divider"
                  />
                  <div className="justify-self-start flex flex-col items-center">
                    <Text
                      variant="primary"
                      className="text-[30px] md:text-[40px] mb-[20px]"
                    >
                      Winner
                    </Text>
                    <Tweet
                      className="md:w-[300px]"
                      textClassname="md:text-[20px]"
                      text={valhallaTweet.winner.content}
                      handle={valhallaTweet.winner.author_username}
                      profile_pic={valhallaTweet.winner.profile_pic}
                      link={valhallaTweet.winner.link}
                      acorns={valhallaTweet.winner.num_acorns}
                    ></Tweet>
                  </div>
                </div>
                <Text variant={"secondary"} className="text-[20px] mt-8">
                  {valhallaTweet.num_acorns} total entries
                </Text>
              </div>
            );
          })
        ) : (
          <Text className="text-[20px] md:text-[40px] m-auto mt-[-15vw]">
            No one worthy
          </Text>
        )}
      </div>
    </>
  );
};

export default Valhalla;
