import { WagmiProvider } from "wagmi";
import { Chain, mainnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { ReactNode } from "react";

const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID;

const queryClient = new QueryClient();

if (!projectId) {
  throw new Error("VITE_WALLET_CONNECT_PROJECT_ID is not set");
}

const includeWalletIds = [
  "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // MetaMask
  "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369", // Rainbow
  "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // Coinbase Wallet
];

const metadata = {
  name: "9wr.xyz",
  description: "Stake Portal",
  url: "https://9wr.xyz",
  icons: [],
};

const chains = [mainnet] as [Chain];
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableInjected: false,
  enableCoinbase: false,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  themeMode: "dark",
  enableAnalytics: true,
  termsConditionsUrl: "/terms-of-use",
  privacyPolicyUrl: "https://www.portalgaming.com/privacy",
  includeWalletIds: includeWalletIds,
  themeVariables: {
    "--w3m-color-mix": "#000000",
    "--w3m-border-radius-master": "0",
    "--w3m-color-mix-strength": 0,
  },
});

interface ProviderProps {
  children: ReactNode;
}

export function Web3ModalProvider({ children }: ProviderProps) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
