import type { FC, ReactNode } from "react";
import { WalletStandardProvider } from "@wallet-standard/react";
import { AuthProvider } from "./auth";
import { Web3ModalProvider } from "./wallet-provider";

export const AppContext: FC<{ children: NonNullable<ReactNode> }> = ({
  children,
}) => {
  return (
    <WalletStandardProvider>
      <Web3ModalProvider>
        <AuthProvider>{children}</AuthProvider>
      </Web3ModalProvider>
    </WalletStandardProvider>
  );
};

export default AppContext;
