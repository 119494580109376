"use client";
import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogHeader } from "@/components/dialog";
import classNames from "classnames";
import { Button } from "./button";
import { Text } from "./text";
import { useAuthContext } from "@/context/auth";
import { Loading } from "./Loading";
import { useAccount, useConnect, useDisconnect, useSignMessage } from "wagmi";
import {
  ACTIONS,
  useAccountsActions,
  useAccountsStore,
} from "@/state/accounts/store";
import { useToastErrors } from "@/hooks/useToastError";

type ModalProps = {
  open?: boolean;
  loading?: boolean;
  onOpenChange: (open: boolean) => void;
};

const ConnectModal = ({ open, onOpenChange }: ModalProps) => {
  const { account } = useAuthContext();
  const { connectors, connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { address, isConnected } = useAccount();
  const [step, setStep] = useState(1);
  const { data: signMessageData, signMessageAsync } = useSignMessage();

  const { getAccount, setAccountEthAddress, clearErrors } =
    useAccountsActions();
  const { error: accountError } = useAccountsStore();

  useToastErrors(accountError, clearErrors, [ACTIONS.UPDATE_ACCOUNT]);

  // Handlers
  const handleOutsideInteract = (event: unknown) => {
    const e = event as React.MouseEvent;
    if ((e.target as HTMLElement)?.closest("wcm-modal")) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (signMessageData && address) {
      const updateAccountEthAddress = async () => {
        try {
          await setAccountEthAddress({ address, signature: signMessageData });
          setStep(3);
        } catch (err) {
          console.error(err);
          setStep(1);
        }
      };

      updateAccountEthAddress();
    }
  }, [signMessageData, address]);

  const handleSelectWallet = async (connector: any) => {
    setStep(2);
    try {
      if (!account) {
        throw Error("Not Authenticated");
      }

      await disconnectAsync();
      await connectAsync({ connector });

      if (isConnected && account) {
        await signMessageAsync({ message: account.id, connector });
      }
    } catch (error) {
      setStep(1);
      console.error("Connection failed:", error);
    }
  };

  const handleConnectWalletFinished = async (open: boolean) => {
    await getAccount();
    onOpenChange(open);
    setStep(1);
  };

  return (
    <Dialog open={open} onOpenChange={handleConnectWalletFinished}>
      <DialogContent
        onInteractOutside={handleOutsideInteract}
        className={"modal modal-dialog-content max-w-[400px] gap-[60px]"}
      >
        {step === 1 && (
          <>
            <DialogHeader className="modal modal-dialog-header items-center">
              <Text variant={"secondary"} size={"xl"}>
                Connect ETH Wallet
              </Text>
            </DialogHeader>
            <div className={classNames("flex flex-col gap-[16px] relative")}>
              {connectors.map((connector) => (
                <Button
                  key={connector.uid}
                  className="!w-full flex flex-1 items-center justify-start gap-4 font-bold p-4"
                  onClick={() => handleSelectWallet(connector)}
                  disabled={step > 1}
                >
                  <p className="subtitle uppercase">{connector.name}</p>
                </Button>
              ))}
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <Loading />
            <Text
              variant={"primary"}
              size={"xl"}
              className="w-full text-center mt-[-30px]"
            >
              Connecting Wallet
            </Text>
          </>
        )}

        {step === 3 && (
          <>
            <DialogHeader className="modal modal-dialog-header items-center">
              <img src={"/images/acorn.svg"} alt="Acorn" />
              <Text className={"text-center"} variant={"secondary"} size={"xl"}>
                ETH Wallet Connected
              </Text>
            </DialogHeader>
            <img
              src="/images/dividerHorizontal.svg"
              className="w-full"
              alt="Divider"
            />
            <Text className={"text-center"} variant={"secondary"} size={"lg"}>
              {`${address?.slice(0, 5)}...${address?.slice(-3)}`}
            </Text>
            <Button
              onClick={() => handleConnectWalletFinished(false)}
              variant={"default"}
              size={"lg"}
            >
              Lets Go
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { ConnectModal };
