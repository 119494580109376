import React, { useEffect, useState } from "react";

import { AcornSpin } from "@/components/acornSpin";
import { Text } from "@/components/text";
import { Button } from "@/components/button";
import { Tweet } from "@/components/Tweet";
import { Link } from "react-router-dom";
import { Dialog, DialogContent } from "@/components/dialog";
import { useAuthContext } from "@/context/auth";
import { useAccountsActions } from "@/state/accounts/store";
import { useDashActions, useDashStore, ACTIONS } from "@/state/dash/store";
import { Loading } from "@/components/Loading";

const Account: React.FC = () => {
  const { account, isAuthenticated } = useAuthContext();
  const { fetchTwitterAuthUrl } = useAccountsActions();
  const { tweets, pastTweets, loading } = useDashStore();
  const { fetchTweetList } = useDashActions();

  const [howToPlayOpen, setHowToPlayOpen] = useState<boolean>(false);
  const [connectTwitterOpen, setConnectTWitterModalOpen] =
    useState<boolean>(false);
  const isTwitted = account && account?.twitter_user_id;

  //Handlers

  const handleConnectTwitter = async () => {
    const url = await fetchTwitterAuthUrl();
    window.location.href = url;
  };

  // Effects

  useEffect(() => {
    if (!tweets) {
      fetchTweetList();
    }
  }, [tweets, fetchTweetList]);

  const renderConnect = () => {
    if (isTwitted) {
      return (
        <div className="flex justify-between items-center p-[12px] sm:px-[32px]">
          <div className="flex flex-row items-center">
            {account && account.twitter_profile_picture_url ? (
              <img
                className="w-[42px] h-[42px] sm:h-[56px] sm:w-[56px]  mr-[14px]"
                src={account.twitter_profile_picture_url}
              ></img>
            ) : (
              <img
                className="h-[56px] w-[56px] mr-[14px]"
                src="/images/twitter.svg"
              ></img>
            )}
            <div className="flex flex-col">
              <Text
                className="text-[30px] sm:text-[20px]"
                variant={"secondary"}
              >
                {account && account.twitter_name}
              </Text>
              <Text variant={"tertiary"} className="text-[13px]">
                @{account && account.twitter_username}
              </Text>
            </div>
          </div>
          <Button
            variant={"ghost"}
            size={"none"}
            className="text-yellow w-fit sm:text-black sm:bg-yellow sm:px-[30px] sm:py-[12px] md:py-[16px] text-[16px] sm:text-[32px]"
            onClick={() => setHowToPlayOpen(true)}
          >
            HOW TO PLAY
          </Button>
        </div>
      );
    } else {
      return (
        <div className="flex justify-between items-center p-[12px] sm:px-[32px]">
          <Text className="text-[20px] sm:text-[32px]" variant={"secondary"}>
            Connect your Twitter to play
          </Text>
          <Button
            variant={"ghost"}
            size={"none"}
            onClick={() => setConnectTWitterModalOpen(true)}
            className="text-yellow w-fit sm:text-black sm:bg-yellow sm:px-[30px] sm:py-[12px] md:py-[16px] text-[16px] sm:text-[32px]"
            disabled={!isAuthenticated}
          >
            CONNECT TWITTER
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <img src="/images/dividerHorizontal.svg"></img>
        {renderConnect()}
        <img src="/images/dividerHorizontal.svg"></img>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[minmax(0,_1fr)_40px_minmax(0,_1fr)]">
        <div className="flex flex-col items-center min-h-[600px]">
          {isTwitted ? (
            <AcornSpin>
              <div className="flex flex-col justify-center">
                <Text className="text-[76px] text-center" variant={"secondary"}>
                  {account?.total_num_acorns || 0}
                </Text>
                <Text variant={"secondary"} className="text-[26px] text-center">
                  Acorns earned
                </Text>
              </div>
            </AcornSpin>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center m-auto">
                <Text
                  className="text-[60px] md:text-[76px] text-center"
                  variant={"secondary"}
                >
                  0
                </Text>
                <Text
                  variant={"secondary"}
                  className="text-[20px] md:text-[26px]"
                >
                  Acorns earned
                </Text>
              </div>
              <img className="w-[20%]" src="images/sqrlEmpty.svg"></img>
            </>
          )}
          <img src="/images/dividerHorizontal.svg"></img>
          <div className="bg-[url('/images/valhalla_no_text.svg')] bg-no-repeat bg-contain flex flex-col justify-center items-center aspect-[2.5/1] w-[calc(100%_+_38px)]">
            <div className="flex flex-col justify-center items-center h-full w-full relative">
              <div className="bg-[url('/images/wheel_of_acorns.svg')] bg-no-repeat bg-contain bg-center absolute top-0 left-0 right-0 bottom-0 opacity-20 pointer-events-none"></div>
              <Text variant={"secondary"} className="text-[71px]">
                Valhalla
              </Text>
              <Text variant={"tertiary"} className="text-[13px] my-[10px]">
                See which degens made it to the realm of the Gods
              </Text>
              <Link to="/valhalla">
                <Text size="lg">ENTER</Text>
              </Link>
            </div>
          </div>
        </div>
        <img
          src="/images/dividerVertical.svg#svgView(preserveAspectRatio(none))"
          className="hidden md:block h-full"
          alt="Divider"
        />
        <div className="p-[10px]">
          {loading[ACTIONS.FETCHING_TWEETS] ? (
            <>
              <Loading />
              <Text className="text-center" variant={"secondary"} size={"lg"}>
                Loading...
              </Text>
            </>
          ) : (
            <>
              <Text variant={"secondary"} className="text-[30px]  mb-[20px]">
                Live Tweets
              </Text>
              {tweets?.length === 0 && <Text>None yet</Text>}
              <div className="grid grid-cols-1 sm:grid-cols-2">
                {tweets?.map((tweet, i) => (
                  <div className="mb-[16px]" key={i}>
                    <img src="/images/dividerHorizontal.svg" alt="" />
                    <Tweet
                      className="pr-[10px]"
                      text={tweet.content}
                      handle={tweet.author_username}
                      link={tweet.link}
                      multiplier={tweet.multiplier}
                      profile_pic={tweet.profile_pic}
                      end={tweet.end_timestamp}
                    />
                  </div>
                ))}
              </div>
              <Text
                variant={"secondary"}
                className="text-[30px] mt-[40px] mb-[20px] hidden md:block"
              >
                Past Tweets
              </Text>
              {pastTweets?.length === 0 && <Text>None yet</Text>}
              <div className="grid-cols-1 sm:grid-cols-2 hidden md:grid opacity-50">
                {pastTweets?.map((tweet, i) => (
                  <div className="mb-[16px]" key={i}>
                    <img src="/images/dividerHorizontal.svg" alt="" />
                    <Tweet
                      className="pr-[10px]"
                      text={tweet.content}
                      handle={tweet.author_username}
                      link={tweet.link}
                      profile_pic={tweet.profile_pic}
                      acorns={tweet.num_acorns}
                      pastTweet={true}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Connect twitter modal */}
      <Dialog
        open={connectTwitterOpen}
        onOpenChange={(open) => setConnectTWitterModalOpen(open)}
      >
        <DialogContent
          onInteractOutside={() => setConnectTWitterModalOpen(false)}
          className={"modal modal-dialog-content max-w-[400px] gap-[60px]"}
        >
          <div className="flex flex-col items-center gap-[24px]">
            <img src="images/sqrlEmpty.svg"></img>
            <Text
              variant={"secondary"}
              className="text-[48px] sm:text-[60px] mb-[30px]"
            >
              Connect Twitter
            </Text>
            <Text variant={"tertiary"} className="text-[20px] text-center">
              Connect your Twitter account so we know who to track activity for.
              Don't screw this up, we will not fix it.
            </Text>
            <Button
              onClick={handleConnectTwitter}
              variant={"default"}
              size={"lg"}
            >
              Connect
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* How to play modal  */}
      <Dialog
        open={howToPlayOpen}
        onOpenChange={(open) => setHowToPlayOpen(open)}
      >
        <DialogContent
          onInteractOutside={() => setHowToPlayOpen(false)}
          className={"modal modal-dialog-content max-w-[400px] gap-[60px]"}
        >
          <div className="flex flex-col items-center">
            <Text
              variant={"secondary"}
              className="text-[48px] sm:text-[60px] mb-[30px]"
            >
              How To Play
            </Text>
            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              You earn acorns by replying to selected tweets. NOTHING ELSE. The
              trick is to be funny. If the gods select your reply out of all the
              replies you go to Valhalla.
            </Text>
            <img
              src="/images/dividerHorizontal.svg"
              alt=""
              className="mt-[10px] mb-[10px]"
            />

            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              likes on your reply tweet <span className="ml-auto">1 point</span>
            </Text>
            <img
              src="/images/dividerHorizontal.svg"
              alt=""
              className="mt-[10px] mb-[10px]"
            />

            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              retweet to your reply tweet{" "}
              <span className="ml-auto">2 points</span>
            </Text>
            <img
              src="/images/dividerHorizontal.svg"
              alt=""
              className="mt-[10px] mb-[10px]"
            />

            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              quote retweet to your reply tweet{" "}
              <span className="ml-auto">2 points</span>
            </Text>
            <img
              src="/images/dividerHorizontal.svg"
              alt=""
              className="mt-[10px] mb-[10px]"
            />

            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              replying to original tweet{" "}
              <span className="ml-auto">10 points</span>
            </Text>
            <img
              src="/images/dividerHorizontal.svg"
              alt=""
              className="mt-[10px] mb-[10px]"
            />

            <Text variant={"tertiary"} className="text-[20px] w-full flex">
              tweet goes to Valhalla{" "}
              <span className="ml-auto">1000 points</span>
            </Text>
            <Button
              onClick={() => setHowToPlayOpen(false)}
              size="lg"
              className="mt-auto md:mt-[30px]"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Account;
