import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { OrdersRepository } from "./repo";
import { Order } from "./domain";
import { getAccessToken } from "../accounts/store";
import { TransactionResp } from "../wallet/domain";

interface OrderStateActions {
  createOrder: (data: Order) => Promise<Order | undefined>;
  getTotalBribes: () => Promise<number>;
  getTotalAcorns: () => Promise<number>;
  confirmOrder: (
    orderId: string,
    data: TransactionResp,
  ) => Promise<Order | undefined>;
  clearErrors: (action?: ACTIONS) => void;
  clearLoading: (action?: ACTIONS) => void;
}

export enum ACTIONS {
  CREATING_ORDER = "CREATING_ORDER",
  CONFIRMING_ORDER = "CONFIRMING_ORDER",
  FETCHING_BRIBES = "FETCHING_BRIBES",
  FETCHING_ACORNS = "FETCHING_ACORNS",
}
const initialActionState = <T>(initialValue: T) =>
  Object.values(ACTIONS).reduce(
    (acc, action) => {
      acc[action] = initialValue;
      return acc;
    },
    {} as Record<ACTIONS, T>,
  );

interface OrderState {
  orders: Order[];
  totalBribes: number;
  totalAcorns: number;
  loading: Record<ACTIONS, boolean>;
  error: Record<ACTIONS, StoreError | null | undefined>;
  actions: OrderStateActions;
}

export const useOrdersStore = create<OrderState>()(
  immer((set) => ({
    orders: [],
    loading: initialActionState(false),
    error: initialActionState(null),
    totalBribes: 0,
    totalAcorns: 0,
    actions: {
      createOrder: async (data: Order) => {
        const token = getAccessToken();
        const repo = new OrdersRepository(token?.access_token);
        const ACTION = ACTIONS.CREATING_ORDER;
        set((state) => {
          state.loading[ACTION] = true;
          state.error[ACTION] = null;
        });
        try {
          const order = await repo.createOrder(data);
          set((state) => {
            state.orders = [...state.orders, order];
            state.loading[ACTION] = false;
          });
          return order;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = {
              id: ACTION,
              title: "Order Creation Error",
              message:
                "Failed to created your order. The gods have been notified.",
            };
          });
        }
      },
      getTotalBribes: async () => {
        const token = getAccessToken();
        const repo = new OrdersRepository(token?.access_token);
        const ACTION = ACTIONS.FETCHING_BRIBES;
        set((state) => {
          state.loading[ACTION] = true;
          state.error[ACTION] = null;
        });
        try {
          const total = await repo.getTotalBribes();
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = null;
            state.totalBribes = total;
          });
          return total;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = {
              id: ACTION,
              title: "Update Error",
              message:
                "Failed to fetch total bribes. The gods have been notified.",
            };
          });
          return 0;
        }
      },
      getTotalAcorns: async () => {
        const token = getAccessToken();
        const repo = new OrdersRepository(token?.access_token);
        const ACTION = ACTIONS.FETCHING_ACORNS;
        set((state) => {
          state.loading[ACTION] = true;
          state.error[ACTION] = null;
        });
        try {
          const total = await repo.getTotalAcorns();
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = null;
            state.totalAcorns = total;
          });
          return total;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = {
              id: ACTION,
              title: "Update Error",
              message:
                "Failed to fetch total bribes. The gods have been notified.",
            };
          });
          return 0;
        }
      },
      confirmOrder: async (orderId: string, data: TransactionResp) => {
        const token = getAccessToken();
        const repo = new OrdersRepository(token?.access_token);
        const ACTION = ACTIONS.CONFIRMING_ORDER;
        set((state) => {
          state.loading[ACTION] = true;
          state.error[ACTION] = null;
        });
        try {
          const order = await repo.confirmOrder(orderId, data);
          set((state) => {
            const orderIndex = state.orders.findIndex((o) => o.id === orderId);
            let newOrders;
            if (orderIndex > -1) {
              // Replace the existing order
              newOrders = state.orders.map((o) =>
                o.id === orderId ? order : o,
              );
            } else {
              // Order doesn't exist, add as a new entry
              newOrders = [...state.orders, order];
            }
            state.loading[ACTION] = false;
            state.error[ACTION] = null;
            state.orders = newOrders;
          });
          return order;
        } catch (err) {
          set((state) => {
            state.loading[ACTION] = false;
            state.error[ACTION] = {
              id: ACTION,
              title: "Order Confirmation Failed",
              message:
                "Failed to confirm your order. The gods have been notified.",
            };
          });
        }
      },
      clearErrors: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            delete state.error[action];
          } else {
            Object.values(ACTIONS).forEach((action) => {
              delete state.error[action];
            });
          }
        });
      },
      clearLoading: async (action?: ACTIONS) => {
        set((state) => {
          if (action) {
            state.loading[action] = false;
          } else {
            Object.values(ACTIONS).forEach((action) => {
              state.loading[action] = false;
            });
          }
        });
      },
    },
  })),
);

export const useOrderActions = () => useOrdersStore((state) => state.actions);
