import axios, { AxiosResponse, AxiosError, AxiosInstance } from "axios";

/**
 * A number of functions in this module have their coverage ignored
 * when testing. this is due to the complexity of testing interceptors
 * and a known typescript bug that I couldn't figure out.
 *
 * https://github.com/axios/axios/pull/5551
 */

export interface HttpClient {
  get<T>(
    url: string,
    params?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>>;
  post<T>(
    url: string,
    data: unknown,
    headers?: object,
  ): Promise<AxiosResponse<T>>;
  put<T>(
    url: string,
    data: object,
    headers?: object,
  ): Promise<AxiosResponse<T>>;
  patch<T>(
    url: string,
    data: object,
    headers?: object,
  ): Promise<AxiosResponse<T>>;
  delete<T>(url: string, headers?: object): Promise<AxiosResponse<T>>;
}

export class AxiosHttpClient implements HttpClient {
  public api: AxiosInstance;

  constructor(baseUrl: string, accessToken?: string | null) {
    const headers: Record<string, string> = {};

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const config = baseUrl ? { baseURL: baseUrl, headers } : { headers };

    this.api = axios.create(config);
  }

  async get<T>(
    url: string,
    params?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return await this.api.get(url, { params, headers });
  }

  async post<T>(
    url: string,
    data: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return await this.api.post<T>(url, data, {
      headers: headers,
    });
  }

  async put<T>(
    url: string,
    data: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return await this.api.put<T>(url, data, {
      headers: headers,
    });
  }

  async patch<T>(
    url: string,
    data: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return await this.api.patch<T>(url, data, { headers: headers });
  }

  async delete<T>(url: string, headers?: object): Promise<AxiosResponse<T>> {
    return await this.api.delete<T>(url, { headers: headers });
  }
}

export { AxiosError };
