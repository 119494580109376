import { AxiosHttpClient, HttpClient } from "@/shared/api";
import { AccessToken, SigningToken, Account, BribeHistoryList } from "./domain";

const API_URL = import.meta.env.VITE_API_URL;

export class AccountsRepository {
  httpClient: HttpClient;

  constructor(accessToken?: string) {
    this.httpClient = new AxiosHttpClient(API_URL!, accessToken);
  }

  async createSigningToken(address: string): Promise<SigningToken> {
    try {
      const result = await this.httpClient.post<SigningToken>(
        "/v1/auth/signing-token",
        {
          wallet_address: address,
        },
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async createAccessToken(
    address: string,
    signingToken: string,
    signatureHash: string,
    publicKey: string,
  ): Promise<AccessToken> {
    try {
      const result = await this.httpClient.post<AccessToken>(
        "/v1/auth/access-token",
        {
          address: address,
          signature_hash: signatureHash,
          signing_token: signingToken,
          public_key: publicKey,
        },
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async setEthAddress({
    address,
    signature,
  }: {
    address: string;
    signature: string;
  }): Promise<Account> {
    try {
      const result = await this.httpClient.post<Account>(
        "/v1/auth/eth-address",
        { wallet_address: address, signature_hash: signature },
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getSession(): Promise<Account> {
    try {
      const result = await this.httpClient.get<Account>("/v1/auth/me");
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getTwitterAuthUrl(): Promise<{ authorize_url: string }> {
    try {
      const result = await this.httpClient.get<{ authorize_url: string }>(
        "/v1/auth/twitter/authorize",
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getBribeHistoryList(): Promise<BribeHistoryList> {
    try {
      const result = await this.httpClient.get<BribeHistoryList>("/v1/orders");
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
