import * as React from "react";
import { AcornSpin } from "./acornSpin";

const Loading = React.forwardRef(() => {
  return (
    <>
      <AcornSpin acornSize={80} smallAcornSize={80} maxWidth={300}>
        <AcornSpin acornSize={40} smallAcornSize={40} maxWidth={160} reverse>
          <AcornSpin acornSize={20} smallAcornSize={20} maxWidth={80}>
            <AcornSpin
              acornSize={10}
              smallAcornSize={10}
              maxWidth={40}
              reverse
            ></AcornSpin>
          </AcornSpin>
        </AcornSpin>
      </AcornSpin>
    </>
  );
});
Loading.displayName = "div";

export { Loading };
