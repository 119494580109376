import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { AccountsRepository } from "./repo";
import { Account, AccessToken, BribeHistoryList } from "./domain";
import { persist } from "zustand/middleware";

interface AccountStateActions {
  createSigningToken: (address: string) => Promise<string>;
  createAccessToken: (
    address: string,
    signingToken: string,
    signatureHash: string,
    publicKey: string,
  ) => Promise<AccessToken | null>;
  getAccount: () => Promise<Account>;
  setAccountEthAddress: ({
    address,
    signature,
  }: {
    address: string;
    signature: string;
  }) => Promise<Account>;
  fetchBribeHistoryList: () => Promise<BribeHistoryList>;
  fetchTwitterAuthUrl: () => Promise<string>;
  reset: () => void;
  clearErrors: (action?: ACTIONS) => void;
  clearLoading: (action?: ACTIONS) => void;
}

export enum ACTIONS {
  CREATING_SIGNING_TOKEN = "CREATING_SIGNING_TOKEN",
  CREATING_ACCESS_TOKEN = "CREATING_ACCESS_TOKEN",
  FETCHING_ACCOUNT = "FETCHING_ACCOUNT",
  FETCHING_BRIBE_HISTORY = "FETCHING_BRIBE_HISTORY",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
}
const initialActionState = <T>(initialValue: T) =>
  Object.values(ACTIONS).reduce(
    (acc, action) => {
      acc[action] = initialValue;
      return acc;
    },
    {} as Record<ACTIONS, T>,
  );

interface AccountState {
  account: Account | null;
  bribeHistory: BribeHistoryList | null;
  token: AccessToken | null;
  loading: Record<ACTIONS, boolean>;
  error: Record<ACTIONS, StoreError | null | undefined>;
  actions: AccountStateActions;
}

export const useAccountsStore = create<AccountState>()(
  persist(
    immer((set, get) => ({
      account: null,
      token: null,
      bribeHistory: null,
      loading: initialActionState(false),
      error: initialActionState(null),
      actions: {
        createSigningToken: async (address: string) => {
          const repo = new AccountsRepository();
          const ACTION = ACTIONS.CREATING_SIGNING_TOKEN;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          try {
            const token = await repo.createSigningToken(address);
            set((state) => {
              state.loading[ACTION] = false;
            });
            return token.signing_token;
          } catch (err) {
            set((state) => {
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Authentication Failure",
                message:
                  "There was a problem authenticating your wallet. The gods have been notified.",
              };
            });
            throw err;
          }
        },
        createAccessToken: async (
          walletAddress: string,
          signingToken: string,
          signature: string,
          publicKey: string,
        ) => {
          const repo = new AccountsRepository();
          const ACTION = ACTIONS.CREATING_ACCESS_TOKEN;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          try {
            const token = await repo.createAccessToken(
              walletAddress,
              signingToken,
              signature,
              publicKey,
            );
            set((state) => {
              state.token = token;
              state.loading[ACTION] = false;
            });
            return token;
          } catch (err) {
            set((state) => {
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Authentication Failure",
                message:
                  "There was a problem getting your access token. The gods have been notified.",
              };
            });
            return null;
          }
        },
        getAccount: async () => {
          const ACTION = ACTIONS.FETCHING_ACCOUNT;
          try {
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            const token = get().token?.access_token;
            const repo = new AccountsRepository(token);
            const account = await repo.getSession();
            set((state) => {
              state.account = account;
              state.loading[ACTION] = false;
              state.error[ACTION] = null;
            });
            return account;
          } catch (err) {
            set((state) => {
              state.account = null;
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Authentication Failure",
                message:
                  "There was a problem fetching your account. The gods have been notified.",
              };
            });
            throw err;
          }
        },
        fetchBribeHistoryList: async () => {
          const ACTION = ACTIONS.FETCHING_BRIBE_HISTORY;
          try {
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            const token = get().token?.access_token;
            const repo = new AccountsRepository(token);
            const result = await repo.getBribeHistoryList();
            set((state) => {
              state.bribeHistory = result;
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            return result;
          } catch (err) {
            set((state) => {
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Request Failure",
                message:
                  "There was a problem fetching your bribes. The gods have been notified.",
              };
            });
            throw err;
          }
        },
        fetchTwitterAuthUrl: async () => {
          const ACTION = ACTIONS.FETCHING_ACCOUNT;
          try {
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            const token = get().token?.access_token;
            const repo = new AccountsRepository(token);
            const result = await repo.getTwitterAuthUrl();
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            return result.authorize_url;
          } catch (err) {
            set((state) => {
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Authentication Failure",
                message:
                  "There was a problem connecting twitter. The gods have been notified.",
              };
            });
            throw err;
          }
        },
        setAccountEthAddress: async ({ address, signature }) => {
          const ACTION = ACTIONS.UPDATE_ACCOUNT;
          try {
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            const token = get().token?.access_token;
            const repo = new AccountsRepository(token);
            const result = await repo.setEthAddress({ address, signature });
            set((state) => {
              state.loading[ACTION] = true;
              state.error[ACTION] = null;
            });
            return result;
          } catch (err) {
            set((state) => {
              state.loading[ACTION] = false;
              state.error[ACTION] = {
                id: ACTION,
                title: "Update Failure",
                message:
                  "There was a problem connecting your ETH account. The gods have been notified.",
              };
            });
            throw err;
          }
        },
        reset: async () => {
          get().actions.clearLoading();
          get().actions.clearErrors();
          set((state) => {
            state.bribeHistory = null;
            state.account = null;
            state.token = null;
            state.bribeHistory = null;
          });
        },
        clearErrors: async (action?: ACTIONS) => {
          set((state) => {
            if (action) {
              delete state.error[action];
            } else {
              Object.values(ACTIONS).forEach((action) => {
                delete state.error[action];
              });
            }
          });
        },
        clearLoading: async (action?: ACTIONS) => {
          set((state) => {
            if (action) {
              state.loading[action] = false;
            } else {
              Object.values(ACTIONS).forEach((action) => {
                state.loading[action] = false;
              });
            }
          });
        },
      },
    })),
    {
      name: "nwr-storage-account", // name of item in the storage (must be unique)
      partialize: (state) => ({
        token: state.token,
      }),
    },
  ),
);

export const useAccount = () => useAccountsStore((state) => state.account);

export const getAccessToken = () => {
  return useAccountsStore.getState().token;
};

export const useAccountsActions = () =>
  useAccountsStore((state) => state.actions);
