import {
  toast as reactHotToast,
  Toast as HotToast,
  ToastOptions,
} from "react-hot-toast";
import { Text } from "./text";

type ToastType = "success" | "error";

type Props = {
  toast: HotToast;
  title: string;
  message: string;
  type: ToastType;
};

const Toast = (props: Props) => {
  return (
    <div
      className="bg-black w-full p-3 cursor-pointer border border-white md:w-[350px]"
      onClick={() => reactHotToast.dismiss(props.toast.id)}
    >
      <Text variant="secondary" size={"lg"}>
        {props.title}
      </Text>
      <Text variant={"tertiary"} size={"md"}>
        {props.message}
      </Text>
    </div>
  );
};

const doToast = (
  title: string,
  message: string,
  type: ToastType,
  options?: ToastOptions,
) => {
  const toastOptions = {
    duration: type === "error" ? 10000 : 4000,
    ...options,
  };
  reactHotToast.custom(
    (t) => <Toast toast={t} title={title} message={message} type={type} />,
    toastOptions,
  );
};

const toast = {
  success: (title: string, message: string, options?: ToastOptions) =>
    doToast(title, message, "success", options),
  error: (title: string, message: string, options?: ToastOptions) =>
    doToast(title, message, "error", options),
};

export { Toast, toast };
