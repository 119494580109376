import { createContext, useContext, ReactNode } from "react";
import useAuth from "@/hooks/useAuth";
import { Account } from "@/state/accounts/domain";

// Define the shape of your context data for better TypeScript support
interface AuthContextType {
  account: Account | null;
  login: Function;
  logout: Function;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  error: string | null;
}

// Initialize the context with undefined or an appropriate default value
export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
