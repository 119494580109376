import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import classNames from "classnames";

const buttonVariants = cva(
  "flex justify-center items-center font-nordic gap-[10px]",
  {
    variants: {
      variant: {
        default:
          "bg-yellow text-black disabled:bg-opacity-30 hover:bg-opacity-85",
        ghost:
          "bg-transparent text-white disabled:bg-opacity-30 disabled:text-opacity-30 hover:bg-opacity-85",
      },
      size: {
        default: "px-[30px] py-[12px] md:py-[16px] text-[32px] w-full md:w-fit",
        lg: "px-[30px] py-[12px] md:py-[16px] text-[32px] w-full md:w-full",
        icon: "h-[40px] w-[40px] p-0",
        none: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={classNames(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
