import * as satsConnect from "sats-connect";
import type { GetAddressResponse, SignMessageResponse } from "sats-connect";

import type { WalletBackend } from "../../domain";
import { Transaction } from "@/state/wallet/domain";
import { sendBtcTransaction } from "sats-connect";
import { isMobile } from "@/shared/mobile";

const { AddressPurpose, BitcoinNetworkType, getAddress, signMessage } =
  satsConnect;

let address: string | null = null;

export const XverseWallet: WalletBackend = {
  id: "xverse",
  name: "Xverse",
  supportsDeepLink: true,

  connect: async () =>
    new Promise((resolve) => {
      if (
        !(typeof window !== "undefined" && window.XverseProviders) &&
        isMobile
      ) {
        const dappUrl = encodeURIComponent(location.href);
        const encodedUrl = `https://connect.xverse.app/browser?url=${dappUrl}`;
        window.location.href = encodedUrl;
        resolve("DEEPLINK");
      } else {
        const getAddressOptions = {
          payload: {
            purposes: [AddressPurpose.Payment],
            message: "Address for receiving payments",
            network: {
              type: BitcoinNetworkType.Mainnet,
            },
          },
          onFinish: (response: GetAddressResponse) => {
            address = response.addresses[0].address;
            resolve(response.addresses[0].address);
          },
          onCancel: () => resolve(null),
        };
        getAddress(getAddressOptions);
      }
    }),

  signMessage: async (message: string) =>
    new Promise((resolve, reject) => {
      if (!address) {
        reject("You need an address to sign a message");
        return;
      }
      const getSignatureOptions = {
        payload: {
          address,
          message,
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (res: SignMessageResponse) => {
          resolve(res as `0x${string}`);
        },
        onCancel: () => resolve(null),
      };
      signMessage(getSignatureOptions);
    }),

  getAddress: async () =>
    new Promise((resolve) => {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Payment],
          message: "Address for receiving payments",
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: (response: GetAddressResponse) => {
          address = response.addresses[0].address;
          resolve(response.addresses[0].address);
        },
        onCancel: () => resolve(null),
      };
      getAddress(getAddressOptions);
    }),

  executeTransfer: async (transaction: Transaction) => {
    return new Promise((resolve, reject) => {
      try {
        const payload = {
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
          message: "Address for receiving payments",
          recipients: [
            {
              address: transaction.destination_address,
              amountSats: BigInt(transaction.amount),
            },
          ],
          senderAddress: transaction.sending_address,
        };

        sendBtcTransaction({
          onCancel: () => {
            const error = new Error();
            reject(error);
          },
          onFinish: (response) => {
            resolve({ txId: response });
          },
          payload: payload,
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  },
};

export const getWallet = (): WalletBackend | null => {
  if (typeof window !== "undefined" && window.XverseProviders) {
    return XverseWallet;
  } else if (isMobile) {
    return XverseWallet;
  } else {
    return null;
  }
};
