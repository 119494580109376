import { Button } from "@/components/button";
import { Text } from "@/components/text";
import React from "react";

import { useAuthContext } from "@/context/auth";
import { useNavigate } from "react-router-dom";
import TotalAcorns from "@/components/TotalAcorns";

const Home: React.FC = () => {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  const renderBuyButton = (className = "") => {
    return (
      <Button className={className} disabled={!isAuthenticated}>
        <a
          className="flex gap-[10px]"
          target="_blank"
          href="https://apollo.xyz"
        >
          Buy <img alt={"Apollo pass logo"} src="images/apollo_logo.svg" />
          Pass
        </a>
      </Button>
    );
  };

  const renderAcornsButton = (className = "") => {
    return (
      <Button className={className} onClick={() => navigate("/acorn-dash")}>
        Earn Acorns
      </Button>
    );
  };

  return (
    <div className={"flex flex-col gap-[64px] text-center items-center"}>
      <div className="flex flex-col gap-[32px] items-center justify-center bg-no-repeat bg-contain bg-center sm:bg-[url('/images/worldtree.svg')] min-h-[700px]">
        <Text className={"text-[100px] sm:text-[223px] sm:mt-[200px]"}>
          9 world Runes
        </Text>
        <img
          src={"/images/worldtree.svg"}
          alt={"Tree of life"}
          className="sm:hidden"
        />
        <div className="flex flex-col gap-[15px] w-full items-center justify-center px-[10px] sm:flex-row sm:gap-[30px]">
          {renderBuyButton()}
          {renderAcornsButton()}
        </div>
      </div>
      <div className={"flex flex-col text-center items-center"}>
        <Text variant={"secondary"} className={"text-[36px] sm:text-[128px]"}>
          Tremors in the Earth disturb the world tree.
        </Text>
        <Text className={"text-[75px] sm:text-[265px]"}>Acorns fall</Text>
        <Text variant={"secondary"} className={"text-[36px] sm:text-[220px]"}>
          Secure your bag
        </Text>
      </div>
      <div className="w-full">
        <img
          src={"/images/sqrl.svg"}
          alt={"A squirrel"}
          className="drop-shadow-[-12px_-12px_7px_#BDFF00] w-full max-h-[800px]"
        />
        <div className="h-0 animate-twitch">
          <img
            src={"/images/sqrl.svg"}
            alt={"A squirrel"}
            className="-translate-y-full w-full opacity-60 max-h-[800px]"
          />
        </div>
      </div>
      <img src={"/images/line.svg"} className="max-w-[900px]" alt="" />
      <Text className="text-[48px] sm:text-[60px]">Total Acorns</Text>
      <div className="flex place-items-baseline">
        <TotalAcorns />
      </div>
      <img src={"/images/line.svg"} className="max-w-[900px]" alt="" />
      <div className="flex flex-col gap-[15px] w-full items-center justify-center px-[10px] sm:flex-row sm:gap-[30px]">
        {renderBuyButton()}
        {renderAcornsButton()}
      </div>
      <div className="flex items-center animate-marquee">
        {[...Array(10)].map((_, i) => (
          <React.Fragment key={i}>
            <Text className="text-[72px] sm:text-[357px]">acornomics</Text>
            <img
              src={"/images/acorn.svg"}
              alt={"An acorn"}
              className="h-[75px] mx-[4px] sm:h-[300px]"
            />
          </React.Fragment>
        ))}
      </div>
      <img
        src={"/images/chart.svg"}
        alt={"A chart"}
        className="max-w-[80%] -mt-[15%] sm:mt-0"
      />
      <Text variant={"secondary"} className="text-[128px]">
        FAQ
      </Text>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          SHOULD I BE BUYING ACORNS WHEN WW3 IS ABOUT TO BREAK OUT?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          YES! WHAT ELSE YOU GONNA BUY, LIFE INSURANCE?
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          WHAT ARE RUNES?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          YOU SHOULD PROBABLY GO BACK TO SOLANA.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          HOW DO I EARN ACORNS?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          BEING AN ETERNAL REPLY GUY TO CHOSEN TWEETS AND SCORING POINTS. GO
          READ THE HOW TO DIPSHIT.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          WHAT ARE BRIBES?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          NOT EVERYONE WANTS TO SHIT ON THEIR TIMELINE. ALL BRIBES ARE
          DISTRIBUTED EQUALLY TO ALL THE PUSSIES.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          WHEN DO I GET RUNES?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          WE WILL ETCH THOSE THINGS AS SOON AS WE CAN AND AIRDROP MONDAY, OR
          SOMETHING. MAYBE THROW A DONO UP FOR PROVENANCE.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          IS THERE A TELEGRAM OR DISCORD?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          NO. FEEL FREE TO DO THAT FOR US THOUGH.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          IS THIS A SCAM?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          IF PRICE GOES DOWN ENOUGH WE ARE SURE IT WILL BE.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          WHAT ARE YOU SPENDING BRIBES ON?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          LIKELY WASH TRADING, COCAINE AND DANCE LESSONS TO EMULATE OUR HERO
          CASEY.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
      <div className={"flex flex-col text-center items-center gap-[12px]"}>
        <Text variant={"secondary"} className="text-[48px] sm:text-[76px]">
          WHERE CAN I TRADE THESE THINGS?
        </Text>
        <Text className="text-[32px] sm:text-[76px]">
          PROBABLY EVERYWHERE BUT MAYBE NOWHERE. WE WILL SEE.
        </Text>
      </div>
      <img src={"/images/line.svg"} className="" alt="" />
    </div>
  );
};

export default Home;
