import { Button } from "@/components/button";
import { useEffect, useState } from "react";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import { useAccountsActions } from "@/state/accounts/store";
import { WalletSelector } from "@/components/wallet-selector";
import { WalletBackend } from "@/state/wallet/domain";
import { Text } from "@/components/text";
import { LogoutModal } from "@/components/LogoutModal";
import { useAuthContext } from "@/context/auth";

const Layout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState<boolean>(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);
  useAccountsActions();
  const { account, login, isAuthenticated, isAuthenticating, logout } =
    useAuthContext();

  const renderLinks = () => (
    <div className="flex flex-col gap-[16px] items-center w-full sm:w-fit sm:flex-row sm:gap-[40px]">
      <Link to="/" className={cn(pathname === "/" ? "underline" : "")}>
        <Text variant={"secondary"} className={cn("text-[32px]")}>
          HOME
        </Text>
      </Link>
      <Button variant={"ghost"} className="text-[32px]" size="none">
        <a href="https://apollo.xyz" target="_blank">
          Buy Pass
        </a>
      </Button>
      <Link
        to="/acorn-dash"
        className={cn(pathname === "/acorn-dash" ? "underline" : "")}
      >
        <Text variant={"secondary"} className="text-[32px]">
          ACORN DASH
        </Text>
      </Link>
    </div>
  );

  const renderTwitter = (className: string = "") => (
    <a href="https://twitter.com/nineworldrunes" target="_blank">
      <img
        src="/images/twitter.svg"
        alt="Twitter"
        className={cn("h-[32px] w-[32px]", className)}
      />
    </a>
  );

  const renderConnect = (className = "") => {
    if (!isAuthenticated) {
      return (
        <div className="flex flex-row gap-[16px] items-center">
          {renderTwitter("hidden sm:block ml-auto")}
          <Button
            onClick={() => setWalletModalOpen(!walletModalOpen)}
            disabled={isAuthenticating}
            className={className}
          >
            {!isAuthenticated && !isAuthenticating && "Connect Wallet"}
            {isAuthenticating && "Connecting..."}
          </Button>
        </div>
      );
    }
    const address = account?.wallet_address;
    const truncatedAddress = `${address?.slice(0, 5)}...${address?.slice(-3)}`;

    return (
      <div className="flex flex-col gap-[16px] mt-[4px] w-full items-center sm:w-fit sm:mt-0 sm:flex-row sm:gap-[20px]">
        {renderTwitter("hidden sm:block")}
        <Link
          to="/account"
          className={cn(pathname === "/account" ? "underline" : "")}
        >
          <Text variant={"secondary"} className="text-[32px]">
            ACCOUNT
          </Text>
        </Link>
        <button onClick={() => setLogoutModalOpen(!logoutModalOpen)}>
          <Text className="text-[32px] mt-[20px] sm:mt-0">
            {truncatedAddress}
          </Text>
        </button>
      </div>
    );
  };

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (menuOpen) {
        if (!(e.target as HTMLElement).closest("header")) {
          setMenuOpen(false);
        }
      }
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [menuOpen]);

  //Handlers
  const handleLogin = (wallet: WalletBackend) => {
    const onLogin = () => {
      setWalletModalOpen(false);
    };

    login({ wallet, onLogin });
  };

  const handleLogout = () => {
    const onLogout = () => {
      setLogoutModalOpen(false);
      navigate("/");
    };
    logout({ onLogout });
  };

  return (
    <div className="flex flex-col w-full h-full max-w-[1500px] m-auto">
      <header
        className={cn(
          "flex flex-col w-full p-[20px]",
          menuOpen ? "border-b" : "",
        )}
      >
        {/* MOBILE */}
        <div className="sm:hidden">
          <div className="flex items-center justify-between w-full mb-[60px]">
            <Link to="/">
              <Text variant={"secondary"} className="text-[32px]">
                9 World Runes
              </Text>
            </Link>
            <div className="flex gap-[16px]">
              {renderTwitter("sm:hidden")}
              <Button
                variant={"ghost"}
                size="icon"
                className="w-fit"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <img
                  src="/images/menu.svg"
                  alt="Menu"
                  className="w-[32px] ml-auto"
                />
              </Button>
            </div>
          </div>
          <div
            className={cn(
              "flex flex-col items-center gap-[12px] w-full",
              menuOpen ? "" : "hidden",
            )}
          >
            {renderLinks()}
            {renderConnect("mt-[60px]")}
          </div>
        </div>
        {/* DESKTOP */}
        <div className="hidden sm:flex justify-between w-full items-center">
          <Link to="/">
            <Text variant={"secondary"} className="text-[32px]">
              9 World Runes
            </Text>
          </Link>
          <div className="flex items-center">{renderLinks()}</div>
          {renderConnect()}
        </div>
      </header>
      <main
        className={cn(
          "w-full",
          menuOpen ? "opacity-50 pointer-events-none" : "",
        )}
      >
        <Outlet />
      </main>
      <footer className="mt-auto">
        <div className="flex flex-col items-center gap-[12px] px-[12px] sm:flex-row w-full sm:justify-between mt-[40px]">
          <Link to="/">
            <Text variant={"secondary"} className="text-[20px]">
              9 WORLD RUNES
            </Text>
          </Link>
          <div className="flex">
            <img
              src={"/images/acorn.svg"}
              className="rotate-[90deg]"
              alt="An Acorn"
            />
            <img
              src={"/images/acorn.svg"}
              className="rotate-[-15deg]"
              alt="An Acorn"
            />
            <img
              src={"/images/acorn.svg"}
              className="rotate-[105deg]"
              alt="An Acorn"
            />
            <img
              src={"/images/acorn.svg"}
              className="rotate-[-5deg]"
              alt="An Acorn"
            />
          </div>
        </div>
      </footer>

      <WalletSelector
        open={walletModalOpen}
        loading={false}
        onOpenChange={setWalletModalOpen}
        onWalletSelect={handleLogin}
      ></WalletSelector>
      <LogoutModal
        open={logoutModalOpen}
        loading={false}
        onOpenChange={setLogoutModalOpen}
        onLogout={handleLogout}
      ></LogoutModal>
    </div>
  );
};

export default Layout;
