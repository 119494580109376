import { AxiosHttpClient, HttpClient } from "@/shared/api";
import { Order } from "./domain";
import { TransactionResp } from "../wallet/domain";

const API_URL = import.meta.env.VITE_API_URL;

export class OrdersRepository {
  httpClient: HttpClient;

  constructor(accessToken?: string | null) {
    console.log(accessToken);
    this.httpClient = new AxiosHttpClient(API_URL!, accessToken);
  }

  async getTotalBribes(): Promise<number> {
    try {
      const result = await this.httpClient.get<number>(
        "/v1/orders/bribes/global-total",
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getTotalAcorns(): Promise<number> {
    try {
      const result = await this.httpClient.get<{ total_acorns: number }>(
        "/v1/tweets/acorns/global-total",
      );
      return result.data?.total_acorns || 0;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async createOrder(data: Order): Promise<Order> {
    try {
      const result = await this.httpClient.post<Order>("/v1/orders", data);
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async confirmOrder(orderId: string, data: TransactionResp): Promise<Order> {
    try {
      const result = await this.httpClient.post<Order>(
        `/v1/orders/${orderId}/confirm`,
        {
          tx_hash: data.txId,
        },
      );
      return result.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
