import { ConnectModal } from "@/components/ConnectEthModal";
import { AcornCounter } from "@/components/acornCounter";
import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { useToastErrors } from "@/hooks/useToastError";
import {
  ACTIONS,
  useAccountsActions,
  useAccountsStore,
} from "@/state/accounts/store";
import React, { useEffect, useState } from "react";

import HRNumbers from "human-readable-numbers";
import { Loading } from "@/components/Loading";

const Account: React.FC = () => {
  const {
    account,
    error: accountError,
    loading: accountsLoading,
  } = useAccountsStore();
  const { getAccount, clearErrors } = useAccountsActions();
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false);

  useToastErrors(accountError, clearErrors, [ACTIONS.FETCHING_ACCOUNT]);

  useEffect(() => {
    if (!account) {
      getAccount();
    }
  }, []);

  return (
    <div className="grid grid-cols-1 gap 4">
      <img src="/images/dividerHorizontal.svg"></img>
      <div className="grid grid-cols-1 md:grid-cols-[1fr,20px,1fr] gap-1">
        <div className="p-5 flex flex-col justify-center items-center">
          {account?.eth_wallet_address ? (
            <>
              <Text
                variant="secondary"
                size="lg"
                className="self-top md:text-start"
              >
                Apollo Pass Acorns
              </Text>
              <div className="flex flex-grow justify-center items-center">
                {accountsLoading[ACTIONS.FETCHING_ACCOUNT] ? (
                  <Loading />
                ) : (
                  <Text className="text-[50px] md:text-[80px]">
                    {HRNumbers.toHumanString(
                      account?.total_num_acorns_from_passes,
                    )}{" "}
                    <span className="text-[32px]">🌰</span>
                  </Text>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <Button
                onClick={() => setConnectModalOpen(!connectModalOpen)}
                disabled={connectModalOpen || !account}
                variant="default"
                size="default"
              >
                Connect ETH Wallet
              </Button>
            </div>
          )}
        </div>
        <div className="hidden md:flex md:justify-center md:items-center">
          <img
            src="/images/dividerVertical.svg"
            className="h-full"
            alt="Divider"
          />
        </div>
        <div className="block md:hidden md:justify-center md:items-center">
          <img
            src="/images/dividerHorizontal.svg"
            className="w-full"
            alt="Divider"
          />
        </div>
        <div className="p-5 grid md:grid-rows-[30px,1fr]">
          <Text
            variant="secondary"
            size="lg"
            className="w-[100px] text-center justify-self-center mb-[30px] md:justify-self-left md:text-left md:w-[100%]"
          >
            Earned acorns{" "}
            <span className="text-yellow">
              {account?.total_num_acorns
                ? HRNumbers.toHumanString(account?.total_num_acorns)
                : 0}{" "}
              acorns
            </span>
          </Text>
          <AcornCounter acornCount={account?.total_num_acorns}></AcornCounter>
        </div>
      </div>
      <img src="/images/dividerHorizontal.svg"></img>
      <ConnectModal
        open={connectModalOpen}
        onOpenChange={setConnectModalOpen}
      ></ConnectModal>
    </div>
  );
};

export default Account;
