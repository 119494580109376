import { useCallback, useEffect, useState } from "react";
import { WalletBackend } from "@/state/wallet/domain";
import { useAccountsActions, useAccountsStore } from "@/state/accounts/store";
import {
  useWalletActions,
  useWalletStore,
  ACTIONS as WALLETS_ACTIONS,
} from "@/state/wallet/store";
import { useToastErrors } from "./useToastError";
import { toast } from "@/components/toast";

const useAuth = () => {
  const { selectedWallet, error: walletError } = useWalletStore();
  const {
    connect,
    signMessage,
    getConnectedWallet,
    reset: resetWallet,
    clearError: clearWalletError,
  } = useWalletActions();
  const { account, token } = useAccountsStore();
  const {
    createSigningToken,
    createAccessToken,
    getAccount,
    reset: resetAccount,
  } = useAccountsActions();

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useToastErrors(walletError, clearWalletError, [
    WALLETS_ACTIONS.CONNECT,
    WALLETS_ACTIONS.SIGN_MESSAGE,
    WALLETS_ACTIONS.EXECUTE_TRANSFER,
  ]);

  // Login function for if we have no account stored
  const login = useCallback(
    async ({
      wallet,
      onLogin,
    }: {
      wallet: WalletBackend;
      onLogin: () => void;
    }) => {
      setIsAuthenticating(true);
      setError(null);
      try {
        const walletAddress = await connect(wallet);
        if (walletAddress === "DEEPLINK") {
          setIsAuthenticating(false);
          setIsAuthenticated(false);
          return;
        }
        const signingToken = await createSigningToken(walletAddress!);
        const signature = await signMessage(signingToken);
        await createAccessToken(
          walletAddress!,
          signingToken,
          signature!,
          "pubkey",
        );
        await getAccount();

        setIsAuthenticated(true);
        setIsAuthenticating(false);
        onLogin();
      } catch (err) {
        const error = err as StoreError;
        console.info(error);
        toast.error("Authentication Failed", error.message);
      } finally {
        setIsAuthenticating(false);
      }
    },
    [connect, createSigningToken, signMessage, createAccessToken, getAccount],
  );

  const logout = useCallback(async ({ onLogout }: { onLogout: () => void }) => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setError(null);
    resetWallet();
    resetAccount();
    onLogout();
  }, []);

  useEffect(() => {
    if (token && !account) {
      try {
        setIsAuthenticating(true);
        getAccount();
        setIsAuthenticating(false);
      } catch (err) {
        setIsAuthenticated(false);
        const error = err as StoreError;
        console.error(error);
        toast.error(
          "Authentication Failed",
          "The gods have been notified of your issue.",
        );
      } finally {
        setIsAuthenticating(false);
      }
    }
  }, [token, account, getAccount]);

  // If we have an account and no selected wallet, try to connect the wallet
  useEffect(() => {
    (async () => {
      if (account && !selectedWallet && !isAuthenticating) {
        setIsAuthenticating(true);
        const wallet = getConnectedWallet();
        setIsAuthenticating(false);
        if (!wallet) {
          toast.error(
            "Wallet Connection",
            "Failed to connect wallet. Please try again.",
          );
        } else {
          setIsAuthenticated(true);
        }
      }
    })();
  }, [account, isAuthenticating, selectedWallet, getConnectedWallet]);

  return { account, login, logout, isAuthenticated, isAuthenticating, error };
};

export default useAuth;
