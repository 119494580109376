import cn from "classnames";
import { Text } from "./text";
import { useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";

type TweetProps = {
  className?: string;
  text: string;
  handle: string;
  link?: string;
  profile_pic: string;
  multiplier?: number;
  end?: string;
  acorns?: number;
  pastTweet?: boolean;
  textClassname?: string;
};

const Tweet = ({
  className,
  text,
  handle,
  link,
  profile_pic,
  multiplier,
  end,
  acorns,
  pastTweet,
  textClassname,
}: TweetProps) => {
  const [timeLeft, setTimeLeft] = useState<string>("0");

  useEffect(() => {
    const interval = setInterval(() => {
      if (end) {
        const now = new Date();
        const end_date = new Date(end);
        if (now >= end_date) {
          clearInterval(interval);
          setTimeLeft("Time's up");
        } else {
          const duration = intervalToDuration({ start: now, end: end_date });
          let formattedTime = "";
          const years = duration.years ?? 0;
          const months = duration.months ?? 0;
          const days = duration.days ?? 0;
          const hours = duration.hours ?? 0;
          const minutes = duration.minutes ?? 0;
          const seconds = duration.seconds ?? 0;
          if (years > 0 || months > 0) {
            formattedTime = `${years * 12 + months} months`;
          } else if (days > 1) {
            formattedTime = `${duration.days} days`;
          } else if (duration.days === 1) {
            formattedTime = "1 day";
          } else if (hours > 0) {
            formattedTime = `${duration.hours} hours`;
          } else if (minutes > 0) {
            formattedTime = `${duration.minutes} mins`;
          } else if (seconds > 0) {
            formattedTime = `${duration.seconds}s`;
          }
          setTimeLeft(formattedTime.trim());
          setTimeLeft(formattedTime.trim());
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [end]);

  return (
    <div className={cn("flex flex-col", className)}>
      <Text variant={"secondary"} className="text-[17px] mt-[14px]">
        {multiplier || "1"}x multiplier
      </Text>
      <div className="flex w-full mt-[14px]">
        <img
          src={profile_pic}
          alt="profile pic"
          height={48}
          width={48}
          className="h-[48px] w-[48px] pr-[10px] flex-shrink-0 box-content"
        />
        <div className="flex flex-col w-full">
          <Text
            variant={"tertiary"}
            className={cn("text-[14px] line-clamp-2", textClassname)}
          >
            {text}
          </Text>
          <div className="flex pt-[14px] justify-between items-center flex-wrap w-full">
            <Text variant={"tertiary"} className="text-[13px]">
              {handle}
            </Text>
            {acorns || pastTweet ? (
              <div className="flex items-center">
                <img
                  src="/images/acorn.svg"
                  alt="acorn"
                  className="h-[30px] w-[30px] mr-[5px]"
                ></img>
                <Text variant={"tertiary"} className="text-[13px]">
                  +{acorns} acorns
                </Text>
              </div>
            ) : (
              <>
                {end && (
                  <Text
                    variant={"tertiary"}
                    className="text-[13px] text-red min-w-[10px]"
                  >
                    {timeLeft}
                  </Text>
                )}
                {link && (
                  <a href={link} target="_blank">
                    <Text
                      variant={"tertiary"}
                      className="text-[13px] text-yellow"
                    >
                      Go to Tweet
                    </Text>
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Tweet };
