import { AppContext } from "./context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./routes/Home";
import NotFound from "./routes/NotFound";
import Layout from "./routes/Layout";
import Account from "./routes/Account";
import Valhalla from "./routes/Valhalla";
import Dash from "./routes/Dash";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4123dc3c1c5e21a6126219094a3aa8ee@o4507112049934336.ingest.de.sentry.io/4507112207482960",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
});

function App() {
  return (
    <Router>
      <AppContext>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="account" element={<Account />} />
            <Route path="valhalla" element={<Valhalla />} />
            <Route path="acorn-dash" element={<Dash />} />
          </Route>
        </Routes>
      </AppContext>
      <Toaster position="bottom-right" />
    </Router>
  );
}

export default App;
