import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { WalletBackend, Transaction, TransactionResp } from "./domain";
import { WalletRepository } from "./repo";
import { persist } from "zustand/middleware";

const repo = new WalletRepository();

export enum ACTIONS {
  GET_WALLETS = "GET_WALLETS",
  CONNECT = "CONNECT",
  SIGN_MESSAGE = "SIGN_MESSAGE",
  EXECUTE_TRANSFER = "EXECUTE_TRANSFER",
}

interface WalletStateActions {
  getConnectedWallet: () => Promise<WalletBackend | null>;
  getWallets: () => Promise<{ name: string; wallet: WalletBackend | null }[]>;
  connect: (wallet: WalletBackend) => Promise<string | null>;
  signMessage: (
    signatureMessage: string,
  ) => Promise<`0x${string}` | string | null>;
  executeTransfer: (
    transaction: Transaction,
  ) => Promise<TransactionResp | null>;
  reset: () => void;
  clearError: (action?: ACTIONS) => void;
  clearLoading: (action?: ACTIONS) => void;
}

const initialActionState = <T>(initialValue: T) =>
  Object.values(ACTIONS).reduce(
    (acc, action) => {
      acc[action] = initialValue;
      return acc;
    },
    {} as Record<ACTIONS, T>,
  );

interface WalletState {
  chainId: number | null;
  wallets: { name: string; wallet: WalletBackend | null }[];
  selectedWallet: WalletBackend | null;
  connectedWalletId: string | null;
  address: string | null;
  loading: Record<ACTIONS, boolean>;
  error: Record<ACTIONS, StoreError | null | undefined>;
  actions: WalletStateActions;
}

export const useWalletStore = create<WalletState>()(
  persist(
    immer((set, get) => ({
      wallets: [],
      chainId: null,
      selectedWallet: null,
      connectedWalletId: null,
      address: null,
      loading: initialActionState(false),
      error: initialActionState(null),
      actions: {
        getConnectedWallet: async () => {
          const { selectedWallet, connectedWalletId, actions } = get();

          // Check if there's a selected wallet and return it if present
          if (selectedWallet) {
            return selectedWallet;
          }

          // Check if there's a last connected wallet ID and attempt to reconnect
          if (connectedWalletId) {
            const walletEntries = await actions.getWallets();
            const walletEntry = walletEntries.find(
              (entry) => entry.wallet && entry.wallet.id === connectedWalletId,
            );

            // If the wallet is found, connect to it and return it
            if (walletEntry && walletEntry.wallet) {
              set((state) => {
                state.selectedWallet = walletEntry.wallet;
              });
              return walletEntry.wallet;
            }
          }
          // Return null explicitly if no wallet is connected or found
          return null;
        },
        getWallets: async () => {
          const ACTION = ACTIONS.GET_WALLETS;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          try {
            const wallets = repo.getWallets();
            set((state) => {
              state.wallets = wallets;
              state.loading[ACTION] = false;
            });
            return get().wallets;
          } catch (error) {
            console.error(error);
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Detection Error",
                message:
                  "The gods are angry as they could not find that you own any wallets.",
              };
              state.loading[ACTION] = false;
            });
            return [];
          }
        },
        connect: async (wallet) => {
          const ACTION = ACTIONS.CONNECT;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          try {
            const address = await wallet.connect();
            set((state) => {
              state.connectedWalletId = wallet.id;
              state.address = address;
              state.selectedWallet = wallet;
              state.loading[ACTION] = false;
            });
            return address;
          } catch (error) {
            console.error(error);
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Connection Error",
                message:
                  "We failed to sign your request. The gods demand you try again.",
              };
              state.loading[ACTION] = false;
            });
            return null;
          }
        },
        signMessage: async (signatureMessage: string) => {
          const ACTION = ACTIONS.SIGN_MESSAGE;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          const wallet = get().selectedWallet;
          if (!wallet) {
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Signing Error",
                message: "We failed to sign your request. Please try again",
              };
              state.loading[ACTION] = false;
            });
            return null;
          }
          try {
            const signature =
              await get().selectedWallet?.signMessage(signatureMessage);
            set((state) => {
              state.loading[ACTION] = false;
            });
            return signature || null;
          } catch (error) {
            console.error(error);
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Signing Error",
                message:
                  "We failed to sign your request. The gods demand you try again.",
              };
              state.loading[ACTION] = false;
            });
            return null;
          }
        },
        executeTransfer: async (transaction: Transaction) => {
          const ACTION = ACTIONS.EXECUTE_TRANSFER;
          set((state) => {
            state.loading[ACTION] = true;
            state.error[ACTION] = null;
          });
          const wallet = get().selectedWallet;
          if (!wallet) {
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Error",
                message:
                  "There is no wallet selected. Stop wasting the gods time.",
              };
              state.loading[ACTION] = false;
            });
            return null;
          }
          try {
            const txResp =
              await get().selectedWallet?.executeTransfer(transaction);
            set((state) => {
              state.loading[ACTION] = false;
            });
            return txResp || null;
          } catch (error) {
            set((state) => {
              state.error[ACTION] = {
                id: ACTION,
                title: "Wallet Transfer Error",
                message:
                  "There was an error sending your funds. The gods demand coin!",
              };
              state.loading[ACTION] = false;
            });
            return null;
          }
        },
        reset: async () => {
          get().actions.clearLoading();
          get().actions.clearError();
          set((state) => {
            state.address = null;
            state.selectedWallet = null;
            state.connectedWalletId = null;
          });
        },
        clearError: (action?: ACTIONS) => {
          set((state) => {
            if (action) {
              delete state.error[action];
            } else {
              Object.values(ACTIONS).forEach((action) => {
                delete state.error[action];
              });
            }
          });
        },
        clearLoading: (action?: ACTIONS) => {
          set((state) => {
            if (action) {
              state.loading[action] = false;
            } else {
              Object.values(ACTIONS).forEach((action) => {
                state.loading[action] = false;
              });
            }
          });
        },
      },
    })),
    {
      name: "nwr-storage-wallet", // name of item in the storage (must be unique)
      partialize: (state) => ({
        address: state.address,
        connectedWalletId: state.connectedWalletId,
      }),
    },
  ),
);

export const useWalletActions = () => useWalletStore((state) => state.actions);

const normaliseWalletName = (name: string) =>
  name.toLowerCase().replace(" ", "");

export const useAvailableWallets = (allowedWalletNames: string[]) =>
  useWalletStore((state) => {
    const normalisedNames = allowedWalletNames.map(normaliseWalletName);
    return state.wallets.filter((wallet) =>
      normalisedNames.includes(normaliseWalletName(wallet.name)),
    );
  });
