import { cva, type VariantProps } from "class-variance-authority";
import cn from "classnames";

const textVariants = cva("leading-super-tight", {
  variants: {
    variant: {
      primary: "text-yellow font-nordic uppercase",
      secondary: "text-white font-nordic uppercase",
      tertiary: "text-white font-sedan leading-tight",
    },
    size: {
      md: "text-md",
      xl: "text-[60px]",
      lg: "text-[27px]",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

export interface TextProps
  extends React.ButtonHTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof textVariants> {}

const Text = ({ variant, size, className, ...props }: TextProps) => {
  return (
    <p className={cn(textVariants({ variant, size, className }))}>
      {props.children}
    </p>
  );
};

export { Text };
