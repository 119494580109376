import { WalletBackend } from "../domain";
import { getWallet as getXverseWallet } from "./xverse";
import { getWallet as getUnisatWallet } from "./unisat";
import { getWallet as getOKXWallet } from "./okx";

export class WalletRepository {
  constructor() { }

  getWallets = (): { name: string; wallet: WalletBackend | null }[] => {
    return [
      {
        name: "Xverse",
        wallet: getXverseWallet(),
      },
      {
        name: "UniSat",
        wallet: getUnisatWallet(),
      },
      {
        name: "OKX",
        wallet: getOKXWallet(),
      },
    ];
  };
}
