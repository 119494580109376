import React from "react";
import "./acornSpin.css";

interface AcornSpinProps {
  numberOfAcorns?: number;
  acornSize?: number;
  smallAcornSize?: number;
  maxWidth?: number;
  reverse?: boolean;
  children?: React.ReactNode;
}

const AcornSpin: React.FC<AcornSpinProps> = ({
  children,
  numberOfAcorns = 12,
  acornSize = 120,
  smallAcornSize = 80,
  maxWidth = 500,
  reverse = false,
}) => {
  const container = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const parentWidth = entry.contentRect.width;
        const width = Math.min(parentWidth, maxWidth);
        container.current?.style.setProperty("--r", `${width / 2.5}px`);
        if (width < 390) {
          container.current?.style.setProperty("--d", `${smallAcornSize}px`);
        } else {
          container.current?.style.setProperty("--d", `${acornSize}px`);
        }
      }
    });

    if (container.current?.parentElement) {
      resizeObserver.observe(container.current?.parentElement);
    }

    return () => {
      if (container.current?.parentElement) {
        resizeObserver.unobserve(container.current?.parentElement);
      }
    };
  }, []);

  const imgs = Array.from({ length: numberOfAcorns }, () => ({
    src: `/images/acorn.svg`,
    alt: "Acorn",
  }));

  const containerStyle = {
    "--n": numberOfAcorns,
    "--d": `${acornSize}px`,
    "--dir": reverse ? "reverse" : "normal",
  } as React.CSSProperties;

  return (
    <div className="flex items-center justify-center relative max-w-full w-full p-[20px]">
      <div
        ref={container}
        style={containerStyle}
        className="acorn-spin-container"
      >
        {imgs.map((img, i) => {
          const style =
            i >= 0 ? ({ "--i": i } as React.CSSProperties) : undefined;
          return (
            <div key={i} style={style}>
              <img src={img.src} alt={img.alt} />
            </div>
          );
        })}
      </div>
      <div className="absolute">{children}</div>
    </div>
  );
};

export { AcornSpin };
