import { Text } from "@/components/text";
import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

interface AcornCounterProps {
  acornCount?: number;
}

const AcornCounter: React.FC<AcornCounterProps> = ({ acornCount }) => {
  const [displayedAcorns, setDisplayedAcorns] = useState<string[] | undefined>(
    undefined,
  );
  const [writtenAcornCount, setWrittenAcornCount] = useState<string>("");
  const [width, setWidth] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Assuming you are only observing one element
        setWidth(entry.contentRect.width - 40);
      }
    });

    const targetRef = ref.current;

    if (targetRef) {
      resizeObserver.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        resizeObserver.unobserve(targetRef);
      }
    };
  }, []);

  useEffect(() => {
    if (acornCount) {
      // Calculate the max displayed and account for x amount text
      const maxDisplayed = Math.round((width * 2 - 100) / 44);
      const displayedCount = Math.min(maxDisplayed, acornCount);
      if (displayedCount <= 0) {
        setDisplayedAcorns([]);
      } else {
        setDisplayedAcorns(Array(displayedCount).fill("acorn"));
      }
      setWrittenAcornCount(
        displayedCount < acornCount
          ? `+ ${acornCount - displayedCount} More`
          : "",
      );
    }
  }, [acornCount, width]);

  const containerClass = cn({
    ["flex flex-wrap content-end"]: true,
    ["justify-center"]: !displayedAcorns?.length,
  });

  return (
    <div className={containerClass} ref={ref}>
      {displayedAcorns?.length ? (
        displayedAcorns?.map((_, i) => {
          return (
            <img
              key={i}
              src="/images/acorn.svg"
              className="w-[44px] h-[60px]"
            ></img>
          );
        })
      ) : (
        <img
          src="images/sqrlEmpty.svg"
          className="justify-self-center ml-[40px] mt-[60px]"
        ></img>
      )}
      {writtenAcornCount && (
        <Text
          variant="secondary"
          className="text-center self-center ml-[6px] text-[18px]"
        >
          {writtenAcornCount}
        </Text>
      )}
    </div>
  );
};

export { AcornCounter };
