import { useOrderActions } from "@/state/orders/store";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "./text";

import HRNumbers from "human-readable-numbers";

const TotalAcorns: React.FC = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { getTotalAcorns } = useOrderActions();
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const fetchTotalAcorns = async () => {
      const _total = await getTotalAcorns();
      setTotal(_total);
    };

    fetchTotalAcorns();

    intervalRef.current = setInterval(fetchTotalAcorns, 30000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [getTotalAcorns]);

  return (
    <Text
      className="text-[120px] md:text-[230px] lg:text-[365px] xl:text-[465px]"
      variant={"secondary"}
    >
      {HRNumbers.toHumanString(total)}
    </Text>
  );
};

export default TotalAcorns;
