import type { WalletBackend } from "../../domain";
import { Transaction } from "@/state/wallet/domain";

import { ua, isMobile } from "@/shared/mobile";

const isOKApp = /OKApp/i.test(ua);

export const OKXWallet: WalletBackend = {
  id: "okx",
  name: "OKX Wallet",
  supportsDeepLink: true,

  connect: async () => {
    if (isMobile && !isOKApp) {
      const encodedUrl =
        "https://www.okx.com/download?deeplink=" +
        encodeURIComponent(
          "okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(location.href),
        );
      window.location.href = encodedUrl;
      return Promise.resolve("DEEPLINK");
    } else {
      try {
        if (typeof window.okxwallet?.bitcoin === "undefined") {
          console.error("OKX Bitcoin Wallet is not installed.");
          throw Error("OKX Bitcoin Wallet is not installed.");
        }
        const { address } = await window.okxwallet.bitcoin.connect();
        return address;
      } catch (error) {
        throw error;
      }
    }
  },

  signMessage: async (message: string) => {
    if (typeof window.okxwallet?.bitcoin === "undefined") {
      console.error("OKX Bitcoin Wallet is not installed.");
      return null;
    }

    try {
      const signature = await window.okxwallet.bitcoin.signMessage(
        message,
        "bip322-simple",
      );
      return signature;
    } catch (error) {
      console.error("Failed to sign message with OKX Bitcoin Wallet", error);
      return null;
    }
  },

  executeTransfer: async (transaction: Transaction) => {
    const txId = await (window as any).okxwallet.bitcoin.sendBitcoin(
      transaction.destination_address,
      transaction.amount,
    );
    return { txId: txId };
  },

  getAddress: async () => {
    if (typeof window.okxwallet?.bitcoin === "undefined") {
      console.error("OKX Bitcoin Wallet is not installed.");
      return null;
    }

    try {
      const accounts = await window.okxwallet.bitcoin.requestAccounts();
      return accounts.length > 0 ? accounts[0] : null;
    } catch (error) {
      console.error("Failed to get address from OKX Bitcoin Wallet", error);
      return null;
    }
  },
};

export const getWallet = (): WalletBackend | null => {
  if (
    typeof window !== "undefined" &&
    window.okxwallet &&
    window.okxwallet.bitcoin
  ) {
    return OKXWallet;
  } else if (isMobile) {
    return OKXWallet;
  } else {
    return null;
  }
};
