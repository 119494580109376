"use client";
import React from "react";
import { Dialog, DialogContent, DialogHeader } from "@/components/dialog";
import { Button } from "./button";

type LogoutModalProps = {
  open?: boolean;
  loading?: boolean;
  onOpenChange: (open: boolean) => void;
  onLogout: () => void;
};

const LogoutModal = ({ open, onOpenChange, onLogout }: LogoutModalProps) => {
  // Handlers
  const handleOutsideInteract = (event: unknown) => {
    const e = event as React.MouseEvent;
    if ((e.target as HTMLElement)?.closest("wcm-modal")) {
      e.preventDefault();
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onInteractOutside={handleOutsideInteract}
        className={"modal modal-dialog-content max-w-[400px] gap-[60px]"}
      >
        <DialogHeader className="modal modal-dialog-header items-center">
          <img src={"/images/acorn.svg"} alt="Acorn" />
        </DialogHeader>
        <div className="flex flex-col items-center gap-[20px]">
          <Button onClick={onLogout} type="submit" className="!w-full">
            Logout
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { LogoutModal as LogoutModal };
