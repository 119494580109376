import type { WalletBackend } from "../../domain";
import { Transaction } from "@/state/wallet/domain";

import { isMobile } from "@/shared/mobile";

export const UnisatWallet: WalletBackend = {
  id: "unisat",
  name: "Unisat",
  supportsDeepLink: false,

  connect: async () => {
    const accounts = await window.unisat?.requestAccounts();
    return accounts ? accounts[0] : null;
  },

  signMessage: async (message: string) => {
    return (await window.unisat?.signMessage(message, "bip322-simple")) as
      | `0x${string}`
      | null;
  },

  getAddress: async () => {
    return (await window.unisat?.getAccounts()) || null;
  },

  executeTransfer: async (transaction: Transaction) => {
    try {
      let txid = await window.unisat?.sendBitcoin(
        transaction.destination_address,
        transaction.amount,
      );
      return { txId: txid || null };
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

export const getWallet = (): WalletBackend | null => {
  if (isMobile) {
    return null;
  }
  if (typeof window !== "undefined" && window.unisat) {
    return UnisatWallet;
  }

  return null;
};
